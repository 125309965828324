#mc_embed_signup_scroll{
  margin-top: 2rem;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
}

input[type="email"]{
  -webkit-appearance: none;
  border-radius: 0;
  font-weight: 500;
  margin-right: 0.25em;
  border: none;
  padding: 0.425em 0.75em;
}

input[type="submit"]{
  @include fs(main);
  @include font(main);
  font-weight: 500;
  @extend .btn;
  @extend .invert;
  display: inline-block;
  margin: 0 !important;
}