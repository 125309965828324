// BORDER-BOX, RESET

html {
  box-sizing: border-box;
}

/*html.touch a:hover, html.touch .btn:hover{
  color: inherit !important;
}*/

*,
*:after,
*:before {
  box-sizing: inherit;
}

* {
  margin: 0;
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-padding: 0 !important;
}


body,
html {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}


main{
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
// __________________________________________________________ //

// TYPE SIZE

html,
body {
  @include font;
  @include fs;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

ol {
  list-style-position: inside;
}

img {
  width: 100%;
}

strong,
b {
  font-weight: normal;
}

i,
em {
  font-style: normal;
}

