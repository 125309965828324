.main-menu{
  padding: 1.5rem;
  position: fixed;
  z-index: 99;
  @include mq(md){
    padding: 3.25rem 3.5rem 3.5rem;
  }
  div:not(.menu-overlay){
    /*margin-left: 0.5em;*/
    &:first-of-type{
      margin-left: 0;
    }
  }
  .container{
    overflow: scroll;
    &::-webkit-scrollbar{
      display: none;
    }
    scrollbar-width: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    width: 100%;
    height: 100% !important;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .social-nav{
    @include mq(md){
      display: none;
    }
    display: flex;
    margin-top: 1.25em;
    li{
      margin: 0.3em;
    }
  }
}

.menu-toggler:hover{
  @extend .invert;
}

.menu-closer{
  position: fixed;
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  i:before{
    font-size: 2.4em;
  }
  @include mq(md){
    top: 3.5rem;
    right: 3.5rem;
    left: unset;
    i:before{
      font-size: 3.4em;
    }
  }
}

.menu-overlay{
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity $transition-duration-default $transition-timing-default;
  background-color: white;
  &.show{
    opacity: 1;
    pointer-events: auto;
    transition: opacity $transition-duration-default $transition-timing-default;
  }
}

.menu-items{
  text-align: center;
  h1{
    line-height: 1.25em;
    @include mq(md){
      line-height: 1.1em;
    }
  }
  h2{
    display: inline-block;
    margin-top: 1em;
    margin-bottom: 0;
  }
}






.themes-picker, .socials{
  position: fixed;
  top:0;
  padding: 0 3.5rem;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 999;
  display: none;
  @include mq(md){
    display: flex;
  }
}

.socials{
  right: 0;
}

.socials li a{
  pointer-events: auto;
}

.theme{
  margin-bottom: 3rem;
  pointer-events: auto;
  cursor: pointer;
  li{
    transition: all $transition-duration-default $transition-timing-default;
    border: 2px solid;
  }
  &:last-of-type{
    margin-bottom: 0;
  }
}

.theme li{
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin-bottom: 0.25rem;
}