// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    sm: (
      fs: 15px,
      lh: 1.3333333333333333em,
    ),
    md: (
      fs: 18px,
      lh: 1.3333333333333333em,
    ),
  ),
  icons: (
    sm: (
      fs: 2.25rem,
      lh: 14px,
    ),
    md: (
      fs: 2rem,
      lh: 1em,
    ),
  ),
  title: (
    sm: (
      fs: 9vw,
      lh: 1.1em,
    ),
    md: (
      fs: 5.7vw,
      lh: 1.1em,
    ),
  ),
  title-2: (
    sm: (
      fs: 5.6vw,
      lh: 1em,
    ),
    md: (
      fs: 2.75vw,
      lh: 1em,
    ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: neue-haas-grotesk-text-pro-65-medium,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: false,
  ),
  icons: (
    name: icons,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),
  title: (
    name: atype-stencil-regular,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),

);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  sm: 0px,
  md: 1024px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
