html, body, main {
  min-height: 100%;
  width: 100%;
  font-weight: 500;
  height: auto;
  overflow-x: hidden;
}

main{
  overflow: hidden;
}

.container {
  padding: 5rem 1.5rem 0;
  height: 100%;
  @include mq(md) {
    padding: 5rem 22% 0;
  }
}

h1 {
  @include font(title);
  @include fs(title);
  margin: 0;
  font-weight: normal;
  text-align: center;
}

h1.stencil {
  text-transform: uppercase;
}

h2 {
  @include font(title);
  @include fs(title-2);
  text-transform: lowercase;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: normal;
}

h3 {
  @include fs(main);
  font-weight: 500;
  text-transform: uppercase;
}

a, a h1{
  transition: all $transition-duration-default $transition-timing-default;
  i{
    transition: all $transition-duration-default $transition-timing-default;
  }
  &:hover{
    transition: all $transition-duration-default $transition-timing-default;
    i{
      transition: all $transition-duration-default $transition-timing-default;
    }
  }
}



.btn {
  transition: all $transition-duration-default $transition-timing-default;
  &:hover{
    transition: all $transition-duration-default $transition-timing-default;
  }
  cursor: pointer;
  display: inline-block;
  word-break: break-word;
  max-width: 80vw;
  padding: 0.25em 0.75em 0.27em;
  text-transform: uppercase;
  border: 2px solid !important;
  border-radius: 1em;

  &.disabled {
    pointer-events: none;
  }

  &.bordered {
    margin-top: 4rem !important;
    font-size: 1.2em;
    margin-right: 0.5em !important;
    &:hover{
      box-shadow: 0.15em 0.2em 0px 0px #000000;
    }
  }
}


.anchors, .filters {
  position: fixed;
  width: 100%;
  left: 0;
  top:0;
  padding: 3.75rem 1.5rem 2rem;
  white-space: nowrap;
  overflow: auto;
  z-index: 9;
  @include mq(md) {
    padding: 3.25rem 1.5rem 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    .btn {
      margin: 0 0.25em;
    }
  }
}

.page-closer {
  position: fixed;
  cursor: pointer;
  z-index: 10;
  top: 1.5rem;
  right: 1.5rem;

  i:before {
    font-size: 2.4em;
  }

  @include mq(md) {
    top: 3.5rem;
    right: 3.5rem;
    left: unset;
    i:before {
      font-size: 3.4em;
    }
  }
}

.social-nav-horizontal{
  @include mq(md){
    display: none;
  }
  display: flex;
  margin-top: 2rem;
  li{
    margin: 0.3em;
  }
}