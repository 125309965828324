.section-text{
  .btn{
    margin: 2rem 0;
  }
  h2{
    @include fs(title);
    @include mq(md){
      @include fs(title-2);
    }
  }
  p:last-of-type{
    margin-bottom: 0;
  }
}

.anchors{
  &::-webkit-scrollbar{
    display: none;
  }
  scrollbar-width: 0;
}

.is-anchor{
  padding-top: 10rem;
  &:first-of-type{
    padding-top: 10rem;
  }
  @include mq(md){
    padding-top: 10rem;
    &:first-of-type{
      padding-top: 10rem;
    }
  }
}


#infos-pratiques .container{
  padding-top: 0rem;
}


#espace-presse, #partenaires{
  main{
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
  }
  .container{
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

#espace-presse{
  main{
    position: static;
    height: auto;
    display: block;
    .container{
      padding-top: 5rem;
    }
    @include mq(md){
      height: 100%;
      width: 100%;
      position: fixed;
      display: flex;
      align-items: center;
      .container{
        padding-top: 0;
      }
    }
  }
  .section-text .btn.bordered:first-of-type{
    margin-top: 4rem !important;
  }
  .section-text .btn.bordered{
    margin-top: 0rem !important;
  }

}

body[data-template="default"]{
  .container{
    padding-bottom: 3rem;
  }
}