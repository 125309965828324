body[data-template="event"]{
  height: 100%;
}

.cover-container{
  transform: translateY(0%);
  transition: transform 0.5s $transition-timing-default;
  &.slide-up{
    transform: translateY(-120%);
    transition: transform 0.5s $transition-timing-default;
  }
}

.cover-container{
  position: fixed;
  z-index: -1;
  width: 100%;
  height: calc(var(--vh) * 100);
  img{
    display: block;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  overflow: hidden;
}

.sticky-title{
  position: sticky;
  height: calc(var(--vh) * 100);
  top:0;
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  align-items: center;
  justify-content: center;
  h1{
    line-height: 0.9em;
  }
}

.tags{
  margin-bottom: 2rem;
}

.event-socials ul{
  display: flex;
  margin: 2rem 0;
  li{
    margin-right: 0.5em;
  }
}

.video-container, .image-container{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
  }

.image-container img{
  left:0;
  top:0;
  height:100%;
  width:100%;
  object-fit: cover;
  position:absolute;
}

.video-container iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

body[data-template="event"]{
  .container{
    padding-bottom: 3rem;
    height: auto;
    background-attachment: scroll !important;
    background-size: 100% !important;
    background-repeat: no-repeat;
  }
}