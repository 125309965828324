.partners-grid{
  display: grid;
  padding: 10%;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 2em;
  .grid-item{
    display: flex;
    align-items: center;
    img{
      aspect-ratio: 1/1;
    }
  }
}