.swiper-container, .swiper-wrapper{
  height: auto;
  position: relative;
  margin-top: 0 !important;
  @include mq(md){

  }
}

.swiper-slide{
  height: 100%;
  overflow: hidden;
}
.swiper-slide-wrap{
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 0 !important;
  padding-top: 100%;
  @include mq(md){
    padding-top: 100%;
  }
  picture{
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  img{
    display: block;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.swiper-button{
  position: absolute;
  height: calc(100% - 10rem);
  width: calc(50% - 8rem);
  z-index: 9;
}
.swiper-next{
  right: 8rem;
}

.swiper-prev{
  left: 8rem;
}

#theme-1 .custom-cursor-next {
  cursor: url("data:image/svg+xml,%3Csvg id='b73ac1c2-049b-4c27-92bf-29a1dadcdc9f' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpolygon points='22.75 6.89 22.35 7.33 30.48 15.76 0.4 15.76 0.4 16.38 30.45 16.38 22.35 24.77 22.75 25.21 31.6 16.05 22.75 6.89' fill='%23faef7d'/%3E%3C/svg%3E") 50 50, auto;
}

#theme-1 .custom-cursor-prev {
  cursor: url("data:image/svg+xml,%3Csvg id='f81ac1d0-6fe4-4449-87b3-d87e217ea8d2' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpolygon points='9.25 25.21 9.65 24.77 1.52 16.34 31.6 16.34 31.6 15.72 1.55 15.72 9.65 7.33 9.25 6.89 0.4 16.05 9.25 25.21' fill='%23faef7d'/%3E%3C/svg%3E") 50 50, auto;
}

.custom-cursor-next {
  cursor: url("data:image/svg+xml,%3Csvg id='b73ac1c2-049b-4c27-92bf-29a1dadcdc9f' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpolygon points='22.75 6.89 22.35 7.33 30.48 15.76 0.4 15.76 0.4 16.38 30.45 16.38 22.35 24.77 22.75 25.21 31.6 16.05 22.75 6.89' fill='%2300000'/%3E%3C/svg%3E") 50 50, auto;
}

.custom-cursor-prev {
  cursor: url("data:image/svg+xml,%3Csvg id='f81ac1d0-6fe4-4449-87b3-d87e217ea8d2' data-name='Calque 1' xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 32 32'%3E%3Cpolygon points='9.25 25.21 9.65 24.77 1.52 16.34 31.6 16.34 31.6 15.72 1.55 15.72 9.65 7.33 9.25 6.89 0.4 16.05 9.25 25.21' fill='%2300000'/%3E%3C/svg%3E") 50 50, auto;
}