body[data-template="programmation"]{
  .container{
    padding-top: 0rem;
    section{
      padding-top: 8.75rem;
      padding-bottom: 0rem;
      min-height: 100vh;
      &:first-of-type{
        min-height: unset;
        padding-top: 8.75rem;
      }
    }
  }
}

.bkg-container{
  position: fixed;
  z-index: -9;
  width: 100%;
  height: 100%;
  left:0;
  right: 0;
}

.events-list{
  text-align: center;
}

.event-item{
  position: relative;
  cursor: pointer;
  margin-bottom: 1.5rem;
  h1{
    line-height: 0.9em;
  }
  transition: opacity $transition-duration-default $transition-timing-default;
  &.hide{
    opacity: 0;
    display: none;
    transition: opacity $transition-duration-default $transition-timing-default 1s;
  }
  &:hover{
    @media (hover: hover) {
      .event-image-container {
        opacity: 1;
        transition: opacity $transition-duration-default $transition-timing-default;
      }
    }
  }
}

.event-image-container{
  pointer-events: none;
  opacity: 0;
  position: absolute;
  height: 0;
  z-index: 3;
  img{
    border-radius: 2em;
    height: 20vw;
    width: 20vw;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}