#home{
  background-size: cover !important;
  background-position: center center !important;
  overflow: hidden;
}

#home main{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-title-wrap{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p{
    margin-top: 1em;
    text-transform: uppercase;
    max-width: 300px;
    @include mq(md){
      max-width: 500px;
    }
  }
}

.go-to-page{
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
}