#festival{
  .header-text{
    margin-top: 5em;
    p{
      text-align: center;
      text-transform: uppercase;
    }
    margin-bottom: 4.6rem;
  }
  .tech-info{
    .tech-info-wrap{
      margin-bottom: 4vw;
      @include mq(md){
        margin-bottom: 0;
      }
    }
    @include mq(md){
      display: flex;
      justify-content: space-between;
    }
    .tech-info-wrap{
      text-align: center;
      p{
        position: relative;
        top: -0.2em;
        @include mq(md){
          top: -0.5em;
        }
      }
    }
  }

  .gallery{
    margin: 4.6rem 0 5rem;
  }

  .page-text{
    margin: 5rem 0 5rem 0;
  }
}

body[data-template="festival"]{
  .newsletter{
    padding-bottom: 3rem;
    .section-text .btn:first-of-type{
      margin-top: 0;
    }
  }
}