// Main theme

#theme-1 {
  /* Backgrounds */
  #home {
    background-color: var(--theme1-text-color);
    color: white;
    .socials a:hover{
      @include notouch{
      i{
        color: var(--theme1-hover-color);
      }
      }
    }
    .menu-toggler:hover{
      @include notouch {
        background-color: var(--theme1-bkg-color);
        color: var(--theme1-text-color);
        border-color: var(--theme1-bkg-color) !important;
      }
    }
  }

  #home:not(.menu-is-active){
    .socials a{
      i{
        color: var(--theme1-text-color);
      }
    }
    .socials a:hover{
      @include notouch{
        i{
          color: var(--theme1-hover-color);
        }
      }
    }
  }

  /* Menu */

  .menu-overlay {
    background-color: var(--theme1-menu-color);

    a {
      color: var(--theme1-bkg-color);
    }

    a:hover {
      @include notouch {
        color: var(--theme1-text-color);
      }
    }
  }

  .menu-is-active {
    color: var(--theme1-bkg-color) !important;
    background-color: var(--theme1-menu-color) !important;
    .socials a:hover{
      @include notouch {
        color: var(--theme1-text-color) !important;
        i {
          color: var(--theme1-text-color) !important;
        }
      }
    }
    .theme:hover{
      @include notouch {
        li {
          border: 2px solid black !important;
        }
      }
    }
  }

  .theme:hover{
    @include notouch {
    li{
      border-color: black !important;
    }
      }
  }

  /* Texts */
  body {
    color: var(--theme1-text-color);
    background-color: var(--theme1-bkg-color);
  }

  h1 {
    color: var(--theme1-text-color);
  }

  h1.invert {
    color: var(--theme1-bkg-color);
  }

  a{
    color: inherit;
  }
  a.invert{
    color: var(--theme1-text-color);
  }

  a:hover {
    @include notouch {
      color: var(--theme1-hover-color);
      h1 {
        color: var(--theme1-hover-color);
      }
    }
  }

  a.active {
    color: var(--theme1-hover-color);
  }




  /* Icons */
  i[class^="icon-"].invert {
    color: var(--theme1-bkg-color);
  }

  /* Buttons */
  .btn {
    color: var(--theme1-bkg-color);
    background-color: var(--theme1-hover-color);
    border-color: var(--theme1-hover-color) !important;
  }

  .btn.invert {
      color: var(--theme1-bkg-color);
      background-color: var(--theme1-text-color);
      border-color: var(--theme1-text-color) !important;
  }
  .filter:not(.active){
    &:hover{
      @include notouch {
        color: var(--theme1-bkg-color);
        background-color: var(--theme1-text-color);
        border-color: var(--theme1-text-color) !important;
      }
    }
  }

  /* Specific Pages */

  /*Festival*/

  .header-text h1{
    color: var(--theme1-bkg-color);
  }

  /*Programmation*/

  .anchor.active{
    color: var(--theme1-bkg-color);
    background-color: var(--theme1-text-color);
    border-color: var(--theme1-text-color) !important;
  }

  /* Bénévoles */

  #benevoles, #festival{
    background-color: var(--theme1-text-color);
    color: var(--theme1-bkg-color);
    .btn.invert {
      background-color: var(--theme1-bkg-color);
      color: var(--theme1-text-color);
      border-color: var(--theme1-bkg-color) !important;
    }
    input[type="email"]{
      color: var(--theme1-text-color);
      &::placeholder{
        color: var(--theme1-text-color);
      }
    }
  }


  /* Contact & Infos Pratiques */
  #infos-pratiques .anchors{
    background: linear-gradient(180deg, var(--theme1-hover-color) 80%, transparent 100%);
  }

  #contact, #infos-pratiques{
    background-color: var(--theme1-hover-color);
    color: var(--theme1-bkg-color);
    .menu-toggler{
      background-color: var(--theme1-text-color);
      color: var(--theme1-hover-color);
    }
    .socials a:hover{
      @include notouch {
        color: var(--theme1-text-color) !important;
      }
    }
    .btn.anchor:hover{
      @include notouch {
        background-color: var(--theme1-bkg-color);
        color: var(--theme1-hover-color);
        border-color: var(--theme1-bkg-color) !important;
      }
    }
    .btn{
      border-color: var(--theme1-text-color) !important;
      background-color: var(--theme1-text-color);
      color: var(--theme1-hover-color);
    }
    .btn.invert {
      background-color: var(--theme1-bkg-color);
      color: var(--theme1-hover-color);
      border-color: var(--theme1-bkg-color) !important;
    }
    .btn.active{
      background-color: var(--theme1-bkg-color);
      color: var(--theme1-hover-color);
      border-color: var(--theme1-bkg-color) !important;
    }
    input[type="email"]{
      color: var(--theme1-hover-color);
      &::placeholder{
        color: var(--theme1-hover-color);
      }
    }
    a:hover{
      @include notouch {
        color: var(--theme1-text-color);
      }
    }
    .section-text svg #Calque_1 path{
      fill: var(--theme1-svgColor1);
    }
    .section-text svg #Calque_2 path{
      fill: var(--theme1-svgColor2);
    }
  }


}





//———————————————————————————————————————————————————————————————
//                        THEME 2
//———————————————————————————————————————————————————————————————

#theme-2{
  a{
    color: black;
  }

  .menu-overlay {
    background-color: black;

    a {
      color: white;
    }

    a:hover {
      @include notouch {
        color: grey;
      }
    }
  }

  .menu-is-active {
    color: white;
    .socials a{
      color: white;
      &:hover{
        @include notouch {
          color: grey;
        }
      }
    }
    .theme:hover{
      @include notouch {
        li {
          border-color: grey !important;
        }
      }
    }
  }

  .theme:hover{
    @include notouch {
      li {
        border-color: black !important;
      }
    }
  }

  a:hover {
    @include notouch {
      color: grey;
      h1 {
        color: grey;
      }
    }
  }

  /* Buttons */
  .btn {
    color: black;
    background-color: white;
    border-color: black !important;
  }
  .btn.invert {
    color: white;
    background-color: black;
    border-color: black;
  }
  .btn.bordered {
    &:hover{
      @include notouch {
        box-shadow: 0.15em 0.2em 0px 0px grey;
      }
    }

  }
  .filtered-color{
    filter: saturate(0%) brightness(70%) contrast(1000%);
  }
  .filter:not(.active){
    &:hover{
      @include notouch {
        color: white;
        background-color: black;
        border-color: black;
      }
    }
  }

  input[type="email"]{
    color: black;
    border: 2px solid black;
    &::placeholder{
    }
  }
  .section-text img{
    filter: grayscale(1);
  }
  #infos-pratiques .anchors{
    background: linear-gradient(180deg, white 80%, transparent 100%);
  }
  #contact, #infos-pratiques, #programmation{
    .anchor:hover{
      @include notouch {
        color: white;
        background-color: black;
        border-color: black !important;
      }
    }
    .btn{
      border-color: black !important;
      background-color: white;
      color: black;
    }
    .btn.invert {
      background-color: black;
      color: white;
      border-color: black !important;
    }
    .btn.active{
      background-color: black;
      color: white;
      border-color: black !important;
    }

    .section-text svg #Calque_1 path{
      fill: var(--theme2-svgColor1);
    }
    .section-text svg #Calque_2 path{
      fill: var(--theme2-svgColor2);
    }
  }

  /*Festival*/

  .header-text h1{
    color: black;
  }
}