$icons-font: "icons";

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  @include font(icons);
  @include fs(icons);
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
  "cross": "\f101",
  "fb": "\f102",
  "in": "\f103",
  "tw": "\f104",
  "yt": "\f105",
);

.icon-cross:before {
  content: map-get($icons-map, "cross");
}
.icon-fb:before {
  content: map-get($icons-map, "fb");
}
.icon-in:before {
  content: map-get($icons-map, "in");
}
.icon-tw:before {
  content: map-get($icons-map, "tw");
}
.icon-yt:before {
  content: map-get($icons-map, "yt");
}

