.text-container{
 p{
   margin-bottom: 1.7rem;
 }
  ul{
    padding-left: 1em;
    list-style-type: disc;
    &:last-of-type{
      margin-bottom: 0em;
    }
    li{
      padding-left: 0.5em;
      &::marker{
        position: absolute;
      }
    }
  }
  ul + p{
    margin-bottom: 0;
    line-height: 1.5em;
  }
  p:empty {
    margin: 0;
    height: 0;
  }

  .btn{
    display: block;
    width: max-content;
  }
}